import { graphql, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import SEO from '../components/SEO';
import SiteLayout from '../layouts/SiteLayout';
import Posts from '../features/blog/components/Posts';

import { Image as ImageClass } from './index.module.css';

// markup
const IndexPage = ({ data }) => (
  <SiteLayout>
    <SEO title="Constant Solutions - About Mattias Fjellvang" />

    <h1>Seasoned Technologist: Bridging Human Connections through Technology</h1>

    <StaticImage
      className={ImageClass}
      src="../assets/images/mattias-fjellvang.jpg"
      alt="Mattias Siø Fjellvang"
      width={200}
    />
    <p>
      Intrigued by the harmonious interplay between people and technology, I&apos;ve dedicated years
      to building platforms and websites that enhance our quality of life. From a young age, I was
      born into a digital world, deploying my first website in 2002 when I was seven years old.
    </p>

    <p>
      My passion for tech extends to my personal blog, where I share insights on diverse topics
      spanning from hacking, startups, and online security to innovation, artificial intelligence,
      and software development. This broad interest spectrum fuels my creativity and ingenuity,
      propelling me towards new technological horizons.
    </p>

    <p>
      Since that initial website deployment in 2002, I&apos;ve founded different technology
      companies, guided several businesses through their digital transformations, and, in the
      process, consumed more caffeine than I dare to admit. With over a decade of professional
      software engineering experience under my belt, I am available to{' '}
      <Link to="/book-me/">lend my expertise to your projects</Link>.
    </p>

    <p>
      My professional journey has equipped me with comprehensive knowledge and proficiency in
      diverse fields: Software Architecture, Software Development (programming), Cybersecurity, both
      front- and backend development (or what some might call &quot;full stack development&quot;),
      algorithm creation, database management, optimization techniques, and much more.
    </p>

    <p>
      If your project could benefit from a seasoned hand or you simply need advice on navigating the
      digital sphere, don&apos;t hesitate to <Link to="/contact/">get in touch</Link>. Let&apos;s
      collaborate and shape the digital future together.
    </p>

    <h2>Recent Blog Posts</h2>
    <Posts posts={data.allMdx.nodes} />
  </SiteLayout>
);

export const query = graphql`
  query HomePageQuery {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }, limit: 4) {
      nodes {
        id
        slug
        excerpt(pruneLength: 250)
        frontmatter {
          title
          date(formatString: "YYYY MMMM Do")
        }
        timeToRead
      }
    }
  }
`;

export default IndexPage;
